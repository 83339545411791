.numberBar {
    width: 120px;
    height: 40px;
    display: flex;
    border: 1px solid #ccc;
    .sub {
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
            background-color: rgb(161, 161, 161);
            cursor: pointer;
        }
    }
    .count {
        width: 38px;
        border-left: 1px solid #ccc;
        border-right: 1px solid #ccc;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        color: #000;
        font-weight: 500;
    }
    .add {
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #000;
        &:hover {
            background-color: rgb(161, 161, 161);
            cursor: pointer;
            color: #fff;
        }
    }
}
