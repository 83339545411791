.nav {
    width: 100%;
    height: 40px;
    background-color: #000;
    color: #fff;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-family: SFNS;
}
header {
    height: 5rem;
    padding: 0 1.875rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .menu {
        display: none;
    }
    .left {
        display: flex;
        align-items: center;
        .logo {
            margin-right: 0.9375rem;
            width: 200px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
        .page {
            display: flex;
            align-items: center;
            li {
                padding: 0 0.375rem;
                font-size: 1.0625rem;
                &:hover {
                    color: #9d5a37;
                }
            }
            .active {
                color: #9d5a37;
            }
        }
    }
    .tool {
        display: flex;
        align-items: center;
        li {
            margin: 0 0.4688rem;
            position: relative;
            .num {
                bottom: -0.3125rem;
                right: -0.3125rem;
                position: absolute;
                width: 0.9375rem;
                height: 0.9375rem;
                border-radius: 1.25rem;
                background-color: #000;
                color: #fff;
                font-size: 0.75rem;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 600;
            }
        }
    }
}

.login-mask {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 3;
    top: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.25);
    transition: all 0.3s;
    display: none;
    .login-box {
        width: 33.125rem;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .header {
            height: 9.375rem;
            background-color: #000;
            padding: 2.5rem;
            position: relative;
            p {
                color: #fff;
                font-size: 1.875rem;
                position: absolute;
                bottom: 1.25rem;
                font-weight: 500;
            }
            .close {
                position: absolute;
                top: 0.9375rem;
                right: 0.9375rem;
            }
        }
        .tab {
            display: flex;
            height: 3.125rem;
            align-items: center;
            border-bottom: 0.0625rem solid rgb(224, 224, 224);
            .btn {
                width: 50%;
                height: 100%;
                font-size: 0.75rem;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 600;
            }
            .active {
                border-bottom: 0.0625rem solid #000;
            }
        }
        .content-box {
            height: 100%;
            overflow-y: scroll;
            .signUp-form {
                padding: 3.75rem 2.5rem;
                .title {
                    font-size: 1rem;
                    margin: 0 0 1.25rem;
                    font-weight: 600;
                }
                .row {
                    p {
                        font-size: 0.9375rem;
                        font-weight: 500;
                        margin: 0 0 0.625rem;
                    }
                    .input-box {
                        position: relative;
                        margin: 0 0 1rem;
                        input {
                            width: 100%;
                            height: 3.125rem;
                            padding: 0.9375rem;
                            border: 0.125rem solid #000;
                            &::placeholder {
                                font-weight: 500;
                            }
                            &:focus {
                                outline: none;
                            }
                        }
                        .icon {
                            position: absolute;
                            top: 0.9375rem;
                            right: 0.9375rem;
                        }
                    }

                    span {
                        display: inline-block;
                        font-size: 0.75rem;
                        color: #931924;
                        font-weight: 600;
                        margin: 0.625rem 0 1.5rem;
                        display: none;
                    }
                    .err {
                        border: 0.125rem solid rgb(147, 25, 36);
                        background-color: rgb(245, 233, 233);
                        color: rgb(147, 25, 36);
                    }
                }
                .title2 {
                    font-size: 1rem;
                    margin: 1.875rem 0 1.25rem;
                    font-weight: 600;
                }
                p {
                    font-size: 0.75rem;
                    font-weight: 600;
                    margin: 0 0 1.5rem;
                }
                .sign-btn {
                    background-color: #000;
                    padding: 0.9375rem;
                    color: #fff;
                    text-align: center;
                    margin: 0 0 1.5rem;
                    transition: all 0.3s;
                    &:hover {
                        cursor: pointer;
                        opacity: 0.8;
                    }
                }
                h6 {
                    font-size: 0.9375rem;
                    margin: 0 0 0.9375rem;
                    font-weight: 400;
                }
                .sing {
                    font-weight: 500;
                    span {
                        text-decoration: underline;
                        &:hover {
                            cursor: pointer;
                        }
                    }
                }
            }
            .signIn-form {
                .form {
                    padding: 3.75rem 2.5rem;
                    .row {
                        p {
                            font-size: 0.9375rem;
                            font-weight: 500;
                            margin: 0 0 0.625rem;
                        }
                        .input-box {
                            position: relative;
                            margin: 0 0 1rem;
                            input {
                                width: 100%;
                                height: 3.125rem;
                                padding: 0.9375rem;
                                border: 0.125rem solid #000;

                                &:focus {
                                    outline: none;
                                }
                            }
                            .icon {
                                position: absolute;
                                top: 0.9375rem;
                                right: 0.9375rem;
                            }
                        }

                        span {
                            display: inline-block;
                            font-size: 0.75rem;
                            color: #931924;
                            font-weight: 600;
                            margin: 0.625rem 0 1.5rem;
                            display: none;
                        }
                        .err {
                            border: 0.125rem solid rgb(147, 25, 36);
                            background-color: rgb(245, 233, 233);
                            color: rgb(147, 25, 36);
                        }
                    }
                    p {
                        font-size: 0.75rem;
                        font-weight: 600;
                        margin: 0 0 1.5rem;
                    }
                    .sign-btn {
                        background-color: #000;
                        padding: 0.9375rem;
                        color: #fff;
                        text-align: center;
                        transition: all 0.3s;
                        &:hover {
                            cursor: pointer;
                            opacity: 0.8;
                        }
                    }
                }
                .new-member {
                    padding: 60px 40px;
                    background-color: #f5f5f5;
                    P {
                        font-size: 15px;
                        font-weight: 600;
                        margin: 0 0 20px;
                    }
                    .create-account {
                        height: 50px;
                        border: 1px solid #000;
                        padding: 15px;
                        text-align: center;
                        font-size: 15px;
                        font-weight: 600;
                        background-color: #fff;
                        transition: all 0.3s;
                        margin: 0 0 30px;
                        &:hover {
                            background-color: #f5f5f5;
                            cursor: pointer;
                        }
                    }
                    .lit {
                        margin: 10px 0;
                        li {
                            margin: 0 0 15px;
                            display: flex;
                            align-items: center;
                            span {
                                display: inline-block;
                                margin-left: 5px;
                                font-size: 14px;
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
        }
    }
}

.show-sign {
    display: block !important;
}

.shoppingCart-mask {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 3;
    top: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.25);
    transition: all 0.3s;
    display: none;
    .shopping-cart {
        position: absolute;
        top: 0;
        right: 0;
        width: 350px;
        height: 100%;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .header {
            font-size: 30px;
            padding: 20px 20px;
            display: flex;
            justify-content: space-between;
            // background-color: #000;
            color: #000;
            border-bottom: 1px solid #e0e0e0;
        }
        .content {
            height: 100%;
            overflow-y: scroll;
            li {
                height: 140px;
                width: 100%;
                padding: 10px 20px;
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid rgba(0, 0, 0, 0.105);
                .left {
                    display: flex;
                    align-items: center;
                    .img {
                        width: 85px;
                        height: 85px;
                        border: 1px solid rgba(0, 0, 0, 0.055);
                        margin-right: 10px;
                        overflow: hidden;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        img {
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }
                    .info-box {
                        width: 180px;
                        .title {
                            width: 100%;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            margin: 0 0 10px;
                            font-weight: 600;
                        }
                        .sku {
                            margin: 0 0 10px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                        .price {
                            span {
                                font-weight: 600;
                                color: #ee7575;
                            }
                        }
                    }
                }
            }
        }
        .footer {
            padding: 20px;
            border-top: 1px solid rgba(0, 0, 0, 0.105);
            .sub-row {
                display: flex;
                justify-content: space-between;
                .title {
                    font-size: 16px;
                }
                .price {
                    font-size: 18px;
                    font-weight: 500;
                    color: #ee7575;
                }
            }
            .btn {
                background-color: #000;
                padding: 15px;
                color: #fff;
                text-align: center;
                transition: all 0.3s;
                margin: 10px 0 0;
                &:hover {
                    cursor: pointer;
                    opacity: 0.8;
                }
            }
        }
        .no-product {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 50px 0;
            .txt {
                margin: 0 0 20px;
            }
            .btn {
                padding: 5px 10px;
                background-color: #000;
                color: #fff;
                font-size: 12px;
            }
        }
    }
}

.show-cart {
    display: block !important;
}

.menu-mask {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 3;
    top: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.25);
    display: none;
    .menu {
        width: 85%;
        height: 100%;
        background-color: #fff;
        .head {
            height: 56px;
            border-bottom: 1px solid #e0e0e0;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 500;
            position: relative;
            font-size: 18px;
            .close {
                position: absolute;
                top: 15px;
                right: 15px;
            }
        }
        .content {
            // padding:1px;
            li {
                font-size: 16px;
                padding: 0 10px;
                height: 40px;
                display: flex;
                align-items: center;
                border-bottom: 1px solid rgba(0, 0, 0, 0.055);
                &:hover {
                    background-color: rgba(0, 0, 0, 0.035);
                }
            }
            .active-li {
                background-color: rgba(0, 0, 0, 0.035);
            }
        }
    }
}

.show-menu {
    display: block !important;
}

.safe {
    position: fixed;
    bottom: 150px;
    right: 8px;
    z-index: 20;
    width: 60px;
    height: 60px;
    border-radius: 50px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    // box-shadow: 1px 3px 10px 2px rgba(0, 0, 0, 0.25);
    .safe-icon {
        z-index: 25;
        width: 60px;
        height: 60px;
        border-radius: 50px;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 1px 3px 10px 2px rgba(0, 0, 0, 0.25);
    }
    .safe-box {
        position: absolute;
        right: 75px;
        width: 280px;
        display: none;
        background-color: #fff;
        border-radius: 10px;
        // border: 1px solid rgba(0, 0, 0, 0.25);
        box-shadow: 1px 3px 10px 2px rgba(0, 0, 0, 0.25);
        padding: 15px;
        z-index: 20;
        transition: all 0.3s;
        .sharp {
            width: 10px;
            height: 10px;
            border-right: 1px solid rgba(0, 0, 0, 0.25);
            border-top: 1px solid rgba(0, 0, 0, 0.25);
            transform: rotate(45deg);
            position: absolute;
            right: -5px;
            top: 90px;
            background-color: #fff;
        }
        .head-img {
            width: 100%;
            img {
                max-width: 100%;
            }
        }
        .image {
            width: 100%;
            height: 40px;
            margin: 15px 0;
            display: flex;
            justify-content: center;
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
        .txt {
            display: flex;
            align-items: center;
            font-size: 12px;
            justify-content: center;
            .text {
                width: 230px;
                margin-left: 10px;
            }
        }
    }
}

.safe-show {
    display: block !important;
}

.service {
    position: fixed;
    bottom: 70px;
    right: 8px;
    z-index: 20;
    width: 60px;
    height: 60px;
    border-radius: 50px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    // box-shadow: 1px 3px 10px 2px rgba(0, 0, 0, 0.25);
    .service-icon {
        z-index: 25;
        width: 60px;
        height: 60px;
        border-radius: 50px;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 1px 3px 10px 2px rgba(0, 0, 0, 0.25);
    }
    .service-box {
        position: absolute;
        right: -2px;
        width: 360px;
        height: 580px;
        bottom: -50px;
        background-color: #fff;
        display: none;
        border-radius: 25px;
        box-shadow: 1px 3px 10px 2px rgba(0, 0, 0, 0.25);
        z-index: 20;
        overflow: hidden;
        .head {
            height: 150px;
            background: linear-gradient(135deg, rgb(42, 39, 218) 0%, rgb(0, 204, 255) 100%);
            position: relative;
            padding: 25px 0;
            &::before {
                border-image-repeat: stretch;
                border-image-slice: 0 0 100%;
                border-image-source: url(../../image/wave.svg);
                border-image-width: 0 0 35px;
                border-width: 0;
                border-color: initial;
                border-left-style: none;
                border-right-style: none;
                border-top-style: none;
                bottom: -13px;
                content: "";
                left: 0px;
                position: absolute;
                width: 100%;
                height: 25px;
                opacity: 0.85;
            }
            .head-bar {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 20px;
                .left {
                    display: flex;
                    align-items: center;
                    .profile {
                        width: 50px;
                        height: 50px;
                        border-radius: 50px;
                        // background-color: #fff;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        overflow: hidden;
                        img {
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }
                    .text {
                        font-size: 24px;
                        font-weight: 600;
                        color: #fff;
                        margin-left: 15px;
                        display: flex;
                        align-items: center;
                        .img {
                            width: 20px;
                            height: 20px;
                            img {
                                max-width: 100%;
                                max-height: 100%;
                            }
                        }
                    }
                }
            }
            .bottom-bar {
                display: flex;
                align-items: center;
                padding: 0 20px;
                margin: 30px 0 0;
                .dian {
                    width: 6px;
                    height: 6px;
                    border-radius: 50px;
                    background-color: #fff;
                }
                .txt {
                    margin-left: 10px;
                    font-size: 18px;
                    font-weight: 450;
                    color: #fff;
                }
            }
        }
        .content {
            height: calc(100% - 150px);
            padding: 20px;
            overflow-y: scroll;
            .contact-box {
                width: 280px;
                border-radius: 25px;
                overflow: hidden;
                border: 1px solid #e5e7eb;
                .message-head {
                    padding: 10px 16px;
                    background-color: #f0f2f7;
                    font-size: 15px;
                    display: flex;
                    justify-content: center;
                    line-height: 20px;
                    color: #212529;
                }
                .message-li {
                    padding: 15px 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 15px;
                    border-top: 1px solid #e5e7eb;
                    color: #165ac0;
                }
            }
            .message-box {
                margin: 20px 0 0;
                .li {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    margin: 20px 0 0;
                    .r {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 180px;
                        height: 45px;
                        border-radius: 25px;
                        background: linear-gradient(135deg, rgb(89, 86, 247), rgb(0, 204, 255));
                        margin: 0 0 20px;
                        color: #fff;
                    }
                }
                .l2 {
                    .l {
                        width: 250px;
                        border-radius: 20px;
                        background-color: #f0f2f7;
                        padding: 10px 16px;
                        display: flex;
                        align-items: center;
                        img {
                            height: 25px;
                        }
                        font-size: 13px;
                        a {
                            color: #165ac0;
                        }
                    }
                }
            }
        }
    }
}

.service-show {
    display: block !important;
}

@media screen and (max-width: 1160px) {
    header {
        padding: 0 20px;
        .menu {
            display: block;
        }
        .left {
            .page {
                display: none;
            }
        }
    }
}

@media screen and (max-width: 375px) {
    // shopping cart
    .shoppingCart-mask {
        .shopping-cart {
            .content {
                li {
                    height: 100px;
                    .left {
                        .img {
                            width: 65px;
                            height: 65px;
                        }
                    }
                }
            }
        }
    }
}
