.about-page {
    .head {
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        .title {
            font-size: 2.1875rem;
        }
    }
    .about-body {
        width: 1200px;
        margin: 0 auto 50px;
        white-space: pre-wrap;
        p {
            line-height: 20px;
            img {
                max-width: 100%;
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .about-page {
        .about-body {
            width: 100%;
            padding: 20px;
        }
    }
}



@media screen and (max-width:375px) {
    .about-page{
        .head{
            height: 70px;
        }
    }
    
}