.orderDetail-page {
    .head {
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        .title {
            font-size: 35px;
        }
    }
    .orderDetail-body {
        width: 800px;
        margin: auto;
        padding: 50px 10px;
        font-size: 16px;
        .order-number {
            padding: 10px;
            font-size: 24px;
            color: #474747;
            // border-bottom: 2px solid #949494;
        }
        .order-li {
            padding: 30px 0;
            display: flex;
            justify-content: center;
            align-items: center;

            border-bottom: 1px dashed rgba(0, 0, 0, 0.105);
            .title {
                color: #474747;
            }
            .img {
                width: 100px;
                // height: 100px;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }
        .li {
            display: flex;
            justify-content: space-between;
            align-items: center;

            border-bottom: 1px dashed rgba(0, 0, 0, 0.105);
            padding: 10px 0;
            // border-bottom: 1px solid #bebebe;
            .info {
                .title {
                    margin: 0 0 5px;
                }
                .sku {
                    // color: #474747;
                    margin: 0 0 5px;
                }
            }
        }
        .detail {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 0;
            border-bottom: 1px dashed rgba(0, 0, 0, 0.105);
            .address {
                li {
                    text-align: right;
                    padding: 2px 0;
                }
            }
            .cancel {
                color: rgb(231, 40, 40);
            }
            .pay {
                color: rgb(22, 146, 22);
            }
        }
        .delivery {
            width: 100%;
            margin: 20px 0;
            box-shadow: rgba(60, 64, 67, 0.3) 1px 1px 2px 1px;
            padding: 20px;

            .title {
                display: flex;
                align-items: center;
                justify-content: center;
                p {
                    font-size: 18px;
                    font-weight: 500;
                    padding: 0 10px;
                }
                img {
                    height: 40px;
                }
            }
            .ship-body {
                margin: 10px 0 0;
                li {
                    margin: 5px 0;
                    padding: 10px 0;
                    display: flex;
                    justify-content: space-between;
                    align-items: start;
                    .left{
                        display: flex;
                        align-items: center;
                        .time{
                            margin-left: 10px;
                            color: #2b2b2c;
                            font-weight: 500;
                            font-size: 12px;
                        }
                    }
                    .content{
                        margin-left: 10px;
                        max-width: 150px;
                        white-space: pre-wrap;
                        font-size: 13px;
                    }
                }
                .dian-li{
                    height: 30px;
                    // border-left: 1px dashed #acabab;
                    margin: 0 10px;
                }
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .orderDetail-page {
        .orderDetail-body {
            width: 100%;
            padding: 0 20px;
        }
    }
}
