footer {
    background-color: #000;
    .content {
        width: 75rem;
        margin: auto;
        padding: 3.125rem 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        ul {
            width: 33%;
            color: #fff;
            padding: .625rem;
            h1 {
                font-weight: normal;
                font-size: 1.25rem;
                margin: 0 0 1.25rem;
            }
            li {
                margin: .625rem 0 0;
                font-size: .9375rem;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                .img {
                    height: 1.875rem;
                    display: flex;
                    align-items: center;
                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
                span{
                    width: 1.25rem;
                    height: 1.25rem;
                    background-color: #fff;
                    border-radius: 1.5625rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: .625rem;
                    margin-top: .625rem;
                }
                .logo{
                    width: 250px;
                    // height: 60px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0 0 15px;
                    img{
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 75rem) {
    footer {
        .content {
            width: 100%;
            padding: 3.125rem 1.25rem;
        }
    }
}

@media screen and (max-width: 51.25rem) {
    footer {
        .content {
            ul {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                // &:nth-child(2) {
                //     margin: 3.125rem 0 0;
                // }
            }
        }
    }
}

@media screen and (max-width: 31.25rem) {
    footer {
        .content {
            ul {
                li {
                    justify-content: center;
                    text-align: left;
                    white-space: pre-wrap;
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: 400;
                    font-family: SFNS;
                    .img {
                        width: 100%;
                        margin: .9375rem 0 0;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }
    }
}
