.order-page {
    .head {
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        .title {
            font-size: 35px;
        }
    }
    .order-body {
        // min-height: 500px;
        width: 1000px;
        margin: 50px auto;
        .search {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 15px;
            input {
                width: 250px;
                height: 40px;
                padding: 10px 20px;
                border-radius: 25px;
                border: 1px solid #bebebe;
                color: #5a5a5a;
                margin-right: 10px;
                &:focus {
                    outline: none;
                }
                &::placeholder{
                    color: #8f8f8f;
                }
            }
        }
        .order-list {
            margin: 50px 0 0;
            // height: 400px;
            // border-top: 2px solid rgb(156, 156, 156);
            padding: 15px;
            .order-li {
                padding: 10px 0;
                // height: 100px;
                color: #6d6d6d;
                border-bottom: 1px solid #bebebe;
                .order {
                    display: flex;
                    justify-content: space-between;
                    padding: 10px 0;
                    .title {
                        font-weight: 600;
                    }
                    .cancel {
                        color: rgb(231, 40, 40);
                    }
                    .pay {
                        color: rgb(22, 146, 22);
                    }
                }
                .btn {
                    display: flex;
                    justify-content: end;
                    padding: 10px 0;
                    .view {
                        padding: 5px 10px;
                        background-color: #000000;
                        color: #fff;
                        font-size: 12px;
                        border-radius: 5px;
                        transition: all .3s;
                        &:hover {
                            cursor: pointer;
                            transform: scale(1.15,1.15);
                        }
                    }
                }
            }
            .order-footer {
                margin: 50px 0 0;
                display: flex;
                justify-content: center;
            }
        }
        .no-order {
            height: 400px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            // .txt{
            //     margin: 10px 0 0;
            // }
        }
    }
}

@media screen and (max-width: 1000px) {
    .order-page {
        .order-body {
            width: 100%;
        }
    }
}

@media screen and (max-width: 375px) {
    .order-page {
        .order-body {
            margin: 0;
            .order-list {
                margin: 0;
            }
        }
    }
}
