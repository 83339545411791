.home-page {
    width: 100%;
    // swiper
    .swiper-row {
        // height: 848px;
        .swiper {
            width: 100%;
            height: 100%;
            overflow: hidden;
            .swiper-wrapper {
                .swiper-slide {
                    background-size: cover;
                    background-position: center center;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    img{
                        width: 100%;
                        // max-height: 100%;
                    }
                }
            }
        }
    }

    // mobile-swiper
    .mobile-swiper {
        display: none;
        .swiper {
            width: 100%;
            height: 100%;
            overflow: hidden;
            .swiper-wrapper {
                .swiper-slide {
                    background-size: cover;
                    background-position: center center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img{
                        width: 100%;
                        // max-height: 100%;
                    }
                }
            }
        }
    }

    // banner
    .little-banner {
        width: 100%;
        img {
            width: 100%;
        }
    }
    .mobile-banner {
        display: none;
        width: 100%;
        img {
            width: 100%;
        }
    }

    // sec1
    .sec1-row {
        margin: 100px 0;
        // background-color: #f5f5f5;
        padding: 20px 0;
        .row {
            width: 1200px;
            margin: auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            .col {
                width: 25%;
                padding: 10px;
                .img {
                    width: 100%;
                    // height: 200px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
                .info {
                    margin: 20px 0 0;
                    text-align: center;
                    font-size: 18px;
                    font-weight: 600;
                }
            }
        }
    }

// banner
.litterBanner-row{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 50px 0 0;
    img{
        max-width: 100%;
    }
    .title-p{
        margin:0 0 50px;
        // font-weight: 600;
        font-size: 30px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

    .related-row {
        width: 1200px;
        margin: 50px auto;
        .title {
            font-size: 35px;
            display: flex;
            justify-content: center;
        }
        .product-row {
            margin: 50px 0 0;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .product-li {
                width: 25%;
                padding: 0.625rem;
                margin: 0 0 1.25rem;
                .img-box {
                    width: 100%;
                    max-height: 15.625rem;
                    overflow: hidden;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                    transition: all 0.3s;
                    &:hover {
                        transform: scale(1.1, 1.1);
                    }
                    .discount-box{
                        position: absolute;
                        top: 10px;
                        left: 0;
                        background-color: #000;
                        padding: 8px;
                        color: #fff;
                        font-size: 16px;
                        font-weight: 600;
                        border-radius: 5px;
                    }
                }
                .txt-box {
                    margin: 10px 0 0;
                    text-align: center;
                    max-height: 6.25rem;
                    min-height: 3.125rem;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 4;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    white-space: wrap;
                    color: #413f3f;
                }
                .star {
                    margin: 10px 0 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .price {
                    margin: 10px 0 0;
                    text-align: center;
                    font-size: 1.25rem;
                    color: #ee7575;
                    span {
                        text-decoration: line-through;
                        color: #7a7979;
                        margin: 0 0.3125rem;
                        font-size: 0.9375rem;
                    }
                }
            }
        }
    }

    .ship-row {
        width: 1200px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin: auto;
        .li {
            width: 25%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            .img {
                img {
                    max-width: 100%;
                }
            }
            .txt {
                font-weight: 600;
                // margin-left: 5px;
                font-size: 18px;
            }
        }
    }

    .sec2-row {
        margin: 50px 0 0;
        background: linear-gradient(#112d57, #000);
        .content {
            width: 1330px;
            margin: auto;
            .box {
                padding: 20px 0;
                display: flex;
                justify-content: space-between;
                .info {
                    color: #fff;
                    .title {
                        padding: 12px 0;
                        font-weight: 600;
                        font-size: 20px;
                    }
                }
                .image {
                    width: 180px;
                    display: none;
                    img {
                        max-width: 100%;
                    }
                }
            }
            .sec2 {
                border-top-left-radius: 25px;
                border-top-right-radius: 25px;
                background-color: #fff;
                padding: 50px 56px 20px;
                position: relative;
                display: flex;
                flex-wrap: wrap;
                overflow: hidden;
                .bgc {
                    width: 270px;
                    position: absolute;
                    right: 10px;
                    bottom: -3px;
                    img {
                        max-width: 100%;
                    }
                }
                .l1 {
                    width: 23%;
                    margin-right: 20px;
                    .head {
                        display: flex;
                        align-items: center;
                        .txt {
                            margin-left: 10px;
                            font-weight: 600;
                        }
                    }
                    .detail {
                        margin: 10px 0 0;
                    }
                }
            }
        }
    }
}



@media screen and (max-width: 1330px) {
    .home-page {
        .sec2-row {
            .content {
                width: 90%;
            }
        }
    }
}
@media screen and (max-width: 1200px) {
    .home-page {
        .sec1-row {
            .row {
                width: 100%;
            }
        }
        .ship-row {
            width: 100%;
        }
        .related-row {
            width: 100%;
        }
    }
}

@media screen and (max-width: 1024px) {
    .home-page {
        .swiper-row {
            display: none !important;
        }
        .mobile-swiper {
            display: block !important;
        }
        .sec2-row {
            .content {
                .sec2 {
                    .bgc {
                        width: 250px;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 900px) {
    .home-page {
        .sec2-row {
            .content {
                .box {
                    .image {
                        display: block !important;
                    }
                }
                .sec2 {
                    padding: 30px 20px 30px;
                    .l1 {
                        width: 100%;
                        padding: 10px;
                        margin-right: 0;
                    }
                    .bgc {
                        display: none !important;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .home-page {
        .sec1-row {
            .row {
                .col {
                    width: 50%;
                }
            }
        }
        .related-row {
            .product-row {
                .product-li {
                    width: 50%;
                }
            }
        }
        .ship-row {
            .li {
                width: 50%;
                padding: 10px 0;
                .img {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 10px 0;
                }
                .txt {
                    font-size: 16px;
                }
            }
        }
    }
}

@media screen and (max-width: 720px) {
    .home-page {
        .little-banner {
            display: none !important;
        }
        .mobile-banner {
            display: block !important;
        }
    }
}

@media screen and (max-width: 450px) {
    .home-page {
        .mobile-swiper {
            height: auto !important;
        }
        .sec1-row {
            margin:0 !important;
            padding: 0;
        }
    }
}
