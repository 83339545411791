.productDetail-content {
    .img-row {
        width: 75rem;
        margin: 50px auto;
        display: flex;
        // flex-wrap: wrap;
        // align-items: center;
        // justify-content: center;
        .left {
            width: 45%;
            margin-right: 50px;
            .image-box {
                width: 100%;
                padding: 1.25rem;
                overflow: hidden;
                .swiper {
                    // width: 370px;
                    // height: 450px;
                    .swiper-slide {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-content: center;
                        flex-wrap: wrap;
                        img {
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }
                }
            }
            .swiper-row {
                // width: 100%;
                // display: flex;
                // justify-content: center;
                .swiper {
                    // width: 100%;
                    .swiper-slide {
                        // width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-content: center;
                        flex-wrap: wrap;
                        img {
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }
                }
                .myThumbsSwiper {
                    display: flex;
                    padding: 0 15px;
                    justify-content: center;
                    overflow: hidden;
                    // .swiper-wrapper {
                    //     // justify-content: center;

                    // }
                    .swiper-slide {
                        // background-color: #eee; // 设置缩略图的背景色
                        border: 1px solid #ccc; // 设置边框
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 5px;
                        height: 100px; /* 控制缩略图的高度 */
                        img {
                            display: block;
                            width: auto; // 保持图片原有比例
                            max-height: 100%; // 最大高度为滑块高度
                            &:hover {
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
        .info-box {
            width: 50%;
            .title {
                font-size: 30px;
                margin: 0 0 20px;
            }
            .star {
                margin: 0 0 10px;
                display: flex;
                // justify-content: space-between;
                align-items: center;
                .inventory{
                    margin-left: 30px;
                    font-size: 18px;
                    span{
                        color: red;
                    }
                }
            }
            .price {
                display: flex;
                align-items: end;
                margin: 0 0 20px;
                .original {
                    font-size: 20px;
                    text-decoration: line-through;
                    color: #7a7979;
                    margin-right: 10px;
                    font-weight: 500;
                }
                .current {
                    color: #ee7575;
                    font-size: 24px;
                    font-weight: 500;
                }
            }
            .sku-row {
                // margin: 0 0 20px;
                .col {
                    margin: 0 0 10px;
                    .title {
                        font-size: 20px;
                        font-weight: 500;
                        span {
                            font-size: 16px;
                            font-weight: normal;
                            padding: 0 10px;
                        }
                    }
                    .select {
                        margin: 10px 0 0;
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        .li {
                            width: auto;
                            min-width: 100px;
                            margin-right: 10px;
                            margin-bottom: 10px;
                            padding: 10px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border: 1px solid rgba(0, 0, 0, 0.105);
                            &:hover {
                                cursor: pointer;
                            }
                        }
                        .active {
                            background-color: #000;
                            color: #fff;
                        }
                    }
                }
            }
            .cart-row {
                margin: 0 0 20px;
                display: flex;
                .addToCart {
                    margin-left: 30px;
                    height: 40px;
                    padding: 0 15px;
                    background-color: #000;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #fff;
                    transition: all 0.3s;
                    font-weight: 500;
                    &:hover {
                        transform: scale(1.05, 1.05);
                        cursor: pointer;
                    }
                }
            }
            .btn-row {
                display: flex;
                .addToCart {
                    margin-right: 30px;
                    height: 40px;
                    padding: 0 15px;
                    background-color: #000;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #fff;
                    transition: all 0.3s;
                    font-weight: 500;
                    &:hover {
                        transform: scale(1.05, 1.05);
                        cursor: pointer;
                    }
                }
                .buy-now {
                    height: 40px;
                    padding: 0 15px;
                    border: 1px solid #000;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: all 0.3s;
                    font-weight: 500;
                    &:hover {
                        transform: scale(1.05, 1.05);
                        cursor: pointer;
                        color: #fff;
                        background-color: #000;
                    }
                }
            }
        }
    }
    .description-row {
        width: 1200px;
        margin: 30px auto 0;
        .title {
            padding: 10px;
            text-align: center;
            font-size: 24px;
            color: #7a7979;
            font-weight: 500;
            border-bottom: 1px solid rgba(0, 0, 0, 0.105);
        }
        .txt {
            padding: 20px;
            display: flex;
            justify-content: center;
            min-height: 300px;
            p {
                line-height: 20px;
                img {
                    max-width: 100%;
                }
            }
        }
    }
    .comment-row {
        width: 1200px;
        margin: 50px auto;

        .title {
            display: flex;
            justify-content: center;
            font-size: 26px;
            color: #7a7979;
            font-weight: 500;
        }
        .comment-ul {
            margin: 50px 0 0;
            .comment-li {
                display: block;
                padding: 15px 0;
                border-bottom: 1px solid rgba(0, 0, 0, 0.105);
                .head {
                    display: flex;
                    align-items: center;
                    padding: 10px 0;
                    .email {
                        margin-left: 20px;
                    }
                }
                .star {
                    padding: 5px 0;
                }
                .content {
                    padding: 10px 0;
                }
                .time {
                    padding: 10px 0;
                }
                p{
                    img{
                        max-width: 100%;
                    }
                }
            }
        }
    }
    .related-row {
        width: 1200px;
        margin: 50px auto;
        .title {
            font-size: 35px;
            display: flex;
            justify-content: center;
        }
        .product-row {
            margin: 50px 0 0;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .product-li {
                width: 25%;
                padding: 0.625rem;
                margin: 0 0 1.25rem;
                .img-box {
                    width: 100%;
                    max-height: 15.625rem;
                    overflow: hidden;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                    transition: all 0.3s;
                    &:hover {
                        transform: scale(1.1, 1.1);
                    }
                    .discount-box{
                        position: absolute;
                        top: 10px;
                        left: 0;
                        background-color: #000;
                        padding: 8px;
                        color: #fff;
                        font-size: 16px;
                        font-weight: 600;
                        border-radius: 5px;
                    }
                }
                .txt-box {
                    margin: 10px 0 0;
                    text-align: center;
                    max-height: 6.25rem;
                    min-height: 3.125rem;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 4;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    white-space: wrap;
                    color: #413f3f;
                }
                .star {
                    margin: 1.25rem 0 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .price {
                    margin: 1.25rem 0 0;
                    text-align: center;
                    font-size: 1.25rem;
                    color: #ee7575;
                    span {
                        text-decoration: line-through;
                        color: #7a7979;
                        margin: 0 0.3125rem;
                        font-size: 0.9375rem;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .productDetail-content {
        .img-row {
            width: 100%;
        }
        .description-row {
            width: 100%;
        }
        .comment-row {
            width: 100%;
            padding: 10px;
        }
        .related-row {
            width: 100%;
        }
    }
}

@media screen and (max-width: 900px) {
    .productDetail-content {
        .img-row {
            .left {
                .swiper-row {
                    .myThumbsSwiper {
                        .swiper-slide {
                            height: 65px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .productDetail-content {
        .img-row {
            margin: 0;
            flex-wrap: wrap;
            .left {
                width: 100%;
                margin-right: 0;
            }
            .info-box {
                margin: 50px 0 0;
                padding: 10px 20px;
                width: 100%;
                display: flex;
                flex-direction: column;
                // justify-content: center;
                // align-items: center;
                .title {
                    // text-align: center;
                }
                .sku-row {
                    display: flex;
                    flex-direction: column;
                    // justify-content: center;
                    // align-items: center;
                    .select {
                        // justify-content: center;
                    }
                }
            }
        }
        .related-row {
            .product-row {
                .product-li {
                    width: 50%;
                }
            }
        }
    }
}

@media screen and (max-width: 375px) {
    .productDetail-content {
        .img-row {
            .left {
                .swiper-row {
                    .myThumbsSwiper {
                        .swiper-slide {
                            height: 65px;
                        }
                    }
                }
            }
        }
    }
}
