.checkout-content {
    .head {
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        .title {
            font-size: 35px;
        }
    }
    .checkout-body {
        display: flex;
        flex-wrap: wrap;
        width: 1200px;
        margin: 50px auto;
        .left-input {
            width: 50%;
            padding: 50px 30px;
            .row {
                width: 100%;
                margin: 0 0 15px;
                .title {
                    margin: 0 0 10px;
                    color: #3b3b3b;
                    span {
                        color: #fc0404;
                    }
                }
                input {
                    width: 100%;
                    padding: 10px 10px;
                    border: 1px solid #ebebeb;
                    height: 45px;
                    &:focus {
                        outline: none;
                    }
                }
                .email {
                    background-color: rgb(250, 250, 250);
                }
                .err-span {
                    display: none;
                    color: #fc0404;
                    padding: 2px 0;
                    font-size: 14px;
                }
                .phone {
                    width: 100%;
                    input {
                        margin: 5px 0;
                        width: 100%;
                        height: 42px;
                        border: 1px solid #ebebeb;
                        padding: 0 60px;
                        // color: #777777;
                        font-size: 14px;
                        overflow: hidden;
                        &::placeholder {
                            font-size: 14px;
                        }
                    }
                }
                // .ReactFlagsSelect-module_flagsSelect__2pfa2{
                //     height: 42px;
                // }
                // .ReactFlagsSelect-module_selectOptionWithlabel__2GpmM{
                //     height: 42px;
                //     color: #000;
                // }
                .react-tel-input .selected-flag {
                    z-index: 13;
                    position: relative;
                    left: 15px;
                    top: 5px;
                    width: 38px;
                    height: 26px;
                    padding: 0 0 0 3px;
                    border-radius: 1px 0 0 3px;
                    border: 0;
                    background-color: #fff;
                    transform: scale(1.2);
                }
                .react-tel-input .flag-dropdown {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    padding: 0;
                    background-color: #fff;
                    border: 0;
                    border-radius: 3px 0 0 3px;
                }
                .react-tel-input {
                    .form-control {
                        box-shadow: none;
                    }
                }

                textarea {
                    width: 100%;
                    min-height: 150px;
                    padding: 10px;
                    border: 1px solid #ebebeb;
                    &::placeholder {
                        font-size: 12px;
                    }
                    &:focus {
                        outline: none;
                    }
                }
            }
            .half-row {
                display: flex;
                justify-content: space-between;
                margin: 0 0 15px;
                .input-box {
                    width: 48%;
                    .title {
                        margin: 0 0 10px;
                        color: #242424;
                    }
                    input {
                        width: 100%;
                        padding: 10px 10px;
                        border: 1px solid #ebebeb;
                        height: 45px;
                        &:focus {
                            outline: none;
                        }
                    }
                    span {
                        display: none;
                        color: #fc0404;
                        padding: 2px 0;
                        font-size: 14px;
                    }
                }
            }
        }
        .right-order {
            width: 50%;
            padding: 50px 30px;
            border-bottom: none;
            background: radial-gradient(circle at 50% 50%, #fff 0, #fff 50%, transparent 0),
                radial-gradient(circle at 50% 50%, #fff 0, #fff 50%, transparent 0);
            background-repeat: repeat-x;
            background-color: #f7f7f7;
            background-size: 16px 12px;
            background-position: 7px calc(100% - -5px), calc(100% + 17px) -5px;
            .title {
                text-align: center;
                font-size: 25px;
            }
            .order-box {
                margin: 30px 0 0;
                width: 100%;
                // height: 300px;
                background-color: #fff;
                padding: 20px;
                .title-head {
                    display: flex;
                    justify-content: space-between;
                    padding: 15px 0;
                    border-bottom: 1px solid #ebebeb;
                    font-weight: 500;
                }
                .product {
                    // border: 1px solid red;
                    padding: 10px 0;
                    .li {
                        width: 100%;
                        padding: 10px 0;
                        // border-bottom: 1px solid #ebebeb;
                        display: flex;
                        justify-content: space-between;
                        font-size: 13px;
                        .left {
                            display: flex;
                            align-items: center;
                            .title {
                                max-width: 130px;
                                white-space: nowrap;
                                font-size: 13px;
                                text-align: left;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                            .sku {
                                max-width: 80px;
                                white-space: nowrap;
                                font-size: 13px;
                                text-align: left;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                            .num {
                                padding: 0 5px;
                            }
                        }
                    }
                }
                .shipping-row {
                    width: 100%;
                    padding: 10px 0;
                    border-bottom: 1px solid #ebebeb;
                    border-top: 1px solid #ebebeb;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .title {
                        font-size: 16px;
                    }
                    .shipArr {
                        // width: 50%;
                        ul {
                            color: #8d8d8d;
                            li {
                                display: flex;
                                padding: 5px 0;
                                font-size: 13px;
                                justify-content: end;
                                .tit {
                                    margin-right: 10px;
                                }
                            }
                        }
                    }
                }
                .subtotal-row {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 10px 0;
                    .title {
                        font-size: 15px;
                    }
                    .price {
                        font-size: 15px;
                        color: #ee7575;
                    }
                }
                .total-row {
                    padding: 15px 0;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .title {
                        font-size: 20px;
                    }
                    .price {
                        font-size: 20px;
                        color: #ee7575;
                        font-weight: 500;
                    }
                }
            }
            .tip-box {
                margin: 30px 0 0;
                color: #5c5b5b;
                word-wrap: break-word;
                padding: 0 10px;
                a {
                    color: #000;
                    font-weight: 450;
                }
            }
            .image {
                margin: 20px 0 0;
                width: 100%;
                img {
                    max-width: 100%;
                }
            }
            .checkout-btn {
                margin: 30px 0 0;
                padding: 15px 0;
                background-color: #000;
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}

.span-show {
    display: inline-block !important;
}
.err-box {
    border-color: #fc0404 !important;
}

.not-check {
    background-color: #bbb !important;
    &:hover {
        cursor: not-allowed !important;
    }
}

// #root {
//     display: flex;
//     align-items: center;
// }

// body {
//     font-family: -apple-system, BlinkMacSystemFont, sans-serif;
//     font-size: 16px;
//     -webkit-font-smoothing: antialiased;
//     display: flex;
//     justify-content: center;
//     align-content: center;
//     height: 100vh;
//     width: 100vw;
// }

// form {
//     width: 30vw;
//     min-width: 500px;
//     align-self: center;
//     box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1), 0px 2px 5px 0px rgba(50, 50, 93, 0.1),
//         0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
//     border-radius: 7px;
//     padding: 40px;
// }

// #payment-message {
//     color: rgb(105, 115, 134);
//     font-size: 16px;
//     line-height: 20px;
//     padding-top: 12px;
//     text-align: center;
// }

// #payment-element {
//     margin-bottom: 24px;
// }

// /* Buttons and links */
// button {
//     background: #5469d4;
//     font-family: Arial, sans-serif;
//     color: #ffffff;
//     border-radius: 4px;
//     border: 0;
//     padding: 12px 16px;
//     font-size: 16px;
//     font-weight: 600;
//     cursor: pointer;
//     display: block;
//     transition: all 0.2s ease;
//     box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
//     width: 100%;
// }

// button:hover {
//     filter: contrast(115%);
// }

// button:disabled {
//     opacity: 0.5;
//     cursor: default;
// }

// /* spinner/processing state, errors */
// .spinner,
// .spinner:before,
// .spinner:after {
//     border-radius: 50%;
// }

// .spinner {
//     color: #ffffff;
//     font-size: 22px;
//     text-indent: -99999px;
//     margin: 0px auto;
//     position: relative;
//     width: 20px;
//     height: 20px;
//     box-shadow: inset 0 0 0 2px;
//     -webkit-transform: translateZ(0);
//     -ms-transform: translateZ(0);
//     transform: translateZ(0);
// }

// .spinner:before,
// .spinner:after {
//     position: absolute;
//     content: "";
// }

// .spinner:before {
//     width: 10.4px;
//     height: 20.4px;
//     background: #5469d4;
//     border-radius: 20.4px 0 0 20.4px;
//     top: -0.2px;
//     left: -0.2px;
//     -webkit-transform-origin: 10.4px 10.2px;
//     transform-origin: 10.4px 10.2px;
//     -webkit-animation: loading 2s infinite ease 1.5s;
//     animation: loading 2s infinite ease 1.5s;
// }

// .spinner:after {
//     width: 10.4px;
//     height: 10.2px;
//     background: #5469d4;
//     border-radius: 0 10.2px 10.2px 0;
//     top: -0.1px;
//     left: 10.2px;
//     -webkit-transform-origin: 0px 10.2px;
//     transform-origin: 0px 10.2px;
//     -webkit-animation: loading 2s infinite ease;
//     animation: loading 2s infinite ease;
// }

// @keyframes loading {
//     0% {
//         -webkit-transform: rotate(0deg);
//         transform: rotate(0deg);
//     }
//     100% {
//         -webkit-transform: rotate(360deg);
//         transform: rotate(360deg);
//     }
// }

// @media only screen and (max-width: 600px) {
//     form {
//         width: 80vw;
//         min-width: initial;
//     }
// }

@media screen and (max-width: 1200px) {
    .checkout-content {
        .checkout-body {
            width: 100%;
        }
    }
}

@media screen and (max-width: 900px) {
    .checkout-content {
        .checkout-body {
            margin: 0 auto;
            .left-input {
                width: 100%;
            }
            .right-order {
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 400px) {
    .checkout-content {
        .checkout-body {
            .left-input {
                padding: 5px 20px;
            }
            .right-order {
                padding: 30px 20px;
                .order-box {
                    padding: 20px 10px;
                }
            }
        }
    }
}
