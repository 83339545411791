.category-content {
    .head {
        position: relative;
        overflow: hidden;
        display: flex;
        justify-content: center;
        img {
            width: 100%;
        }
        .title {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            font-size: 4.875rem;
            opacity: 0.8;
        }
    }
    .product-body {
        .none-product {
            height: 37.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .have-product {
            margin: 15px 0 0;
            .row {
                width: 75rem;
                margin: auto;
                display: flex;
                flex-wrap: wrap;
                .col {
                    width: 25%;
                    padding: 0.625rem;
                    margin: 0 0 1.25rem;
                    .img-box {
                        width: 100%;
                        max-height: 15.625rem;
                        overflow: hidden;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: relative;
                        img {
                            max-width: 100%;
                            max-height: 100%;
                        }
                        transition: all 0.3s;
                        &:hover {
                            transform: scale(1.1, 1.1);
                        }
                        .discount-box{
                            position: absolute;
                            top: 10px;
                            left: 0;
                            background-color: #000;
                            padding: 8px;
                            color: #fff;
                            font-size: 16px;
                            font-weight: 600;
                            border-radius: 5px;
                        }
                    }
                    .txt-box {
                        margin: 10px 0 0;
                        text-align: center;
                        max-height: 6.25rem;
                        min-height: 3.125rem;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        -webkit-line-clamp: 4;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        // display: flex;
                        // align-items: center;
                        white-space: wrap;
                        color: #413f3f;
                    }
                    .star {
                        margin: 10px 0 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .price {
                        margin: 10px 0 0;
                        text-align: center;
                        font-size: 1.25rem;
                        color: #ee7575;
                        span {
                            text-decoration: line-through;
                            color: #7a7979;
                            margin: 0 0.3125rem;
                            font-size: 0.9375rem;
                        }
                    }
                }
            }
        }
    }
    .product-footer {
        margin: 3.125rem 0;
        display: flex;
        justify-content: center;
    }
}

@media screen and (max-width: 75rem) {
    .category-content {
        .product-body {
            .have-product {
                .row {
                    width: 100% !important;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .category-content {
        .product-body {
            .have-product {
                .row {
                    .col {
                        width: 50%;
                    }
                }
            }
        }
    }
}
