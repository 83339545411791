.reload-mask {
    display: none;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 111;
    .reload {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .box {
            // width: 250px;
            // height: 50px;
            background-color: #fff;
            display: flex;
            align-items: center;
            padding: 20px;
            border-radius: 5px;
            .reload-img {
                width: 30px;
                height: 30px;
                animation: reload1 0.7s ease infinite;
                margin-right: 5px;
            }
        }
    }
}

@keyframes reload1 {
    0% {
        transform: rotate(0deg);
    }
    // 25% {
    //     transform: rotate(360deg);
    // }
    // 50% {
    //     // transform: scale(1.1, 1);
    // }
    100% {
        transform: rotate(360deg);
    }
}

.show {
    display: block !important;
}

@media screen and (max-width: 540px) {
    .reload-mask {
        .reload {
            .box {
                p {
                    font-size: 16px;
                }
            }
        }
    }
}
