.pay-content {
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    .box {
        width: 10.625rem;
        height: 200px;
        border-radius: 1.5625rem;
        background-color: #fff;
        box-shadow: 0 0 10px rgba(85, 128, 99, 0.18);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;
        p {
            margin: 1.25rem 0;
        }
        .view {
            position: absolute;
            bottom: 20px;
            right: 20px;
            color: #fff;
            padding: 5px 10px;
            font-size: 12px;
            border-radius: 5px;
            background-color: #000000;
        }
        .close{
            position: absolute;
            top: 15px;
            right: 15px;
        }
    }
}
