.cart-content {
    .head {
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        .title {
            font-size: 35px;
        }
    }
    .cart-body {
        width: 1200px;
        margin: 50px auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: end;
        .product-box {
            width: 100%;
            .header {
                height: 45px;
                background-color: #000;
                color: #fff;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-weight: 600;
                font-size: 16px;
                padding: 0 20px;
                .tool {
                    width: 5%;
                }
                .name {
                    width: 40%;
                }
                .price {
                    width: 10%;
                }
                .quantity {
                    width: 20%;
                }
                .total {
                    width: 10%;
                }
            }
            .item-col {
                padding: 15px 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px dotted #cfc2c2;
                .tool {
                    width: 5%;
                    .icon{
                        &:hover{
                            cursor: pointer;
                        }
                    }
                }
                .name {
                    width: 40%;
                    display: flex;
                    align-items: center;
                    .img {
                        width: 65px;
                        height: 65px;
                        overflow: hidden;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-right: 20px;
                        img {
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }
                    .title {
                        max-width: 150px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        // color: #999;
                    }
                    .sku {
                        max-width: 50px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
                .price {
                    width: 10%;
                    color: #474747;
                }
                .quantity {
                    width: 20%;
                }
                .total {
                    width: 10%;
                    color: #ee7575;
                    font-weight: 600;
                }
            }
        }
        .mini-cart {
            display: none;
            padding: 20px;
            .mini-row {
                width: 100%;
                background-color: #f5f5f5;
                padding: 15px;
                margin: 0 0 30px;
                .tool {
                    display: flex;
                    justify-content: end;
                }
                .img-row {
                    padding: 20px 0;
                    display: flex;
                    justify-content: center;
                    border-bottom: 1px dotted #e1dfdf;
                    .img {
                        width: 230px;
                        // height: 80px;
                        overflow: hidden;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        img {
                            width: 100%;
                        }
                    }
                }
                .detail-row {
                    display: flex;
                    padding: 20px 0;
                    justify-content: space-between;
                    align-items: center;
                    border-bottom: 1px dashed #e1dfdf;
                    .name {
                        display: flex;
                        align-items: center;
                        .title {
                            max-width: 120px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            // color: #999;
                        }
                        .sku {
                            max-width: 50px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }
                .price-row {
                    display: flex;
                    padding: 20px 0;
                    justify-content: space-between;
                    border-bottom: 1px dashed #e1dfdf;
                }
                .quantity-row {
                    display: flex;
                    padding: 20px 0;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: 1px dashed #e1dfdf;
                }
                .total-row {
                    display: flex;
                    padding: 20px 0;
                    justify-content: space-between;
                    .total {
                        color: #ee7575;
                        // font-size: 18px;
                        font-weight: 600;
                    }
                }
            }
        }
        .no-product {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 50px 0;
            p {
                margin: 10px 0;
                font-size: 13px;
                color: #474747;
            }
            .btn {
                background-color: #000;
                color: #fff;
                font-size: 12px;
                padding: 10px;
                border-radius: 5px;
                margin: 10px 0;
                &:hover {
                    // background-color: #000;
                    cursor: pointer;
                }
            }
        }
        .check-row {
            // float: right;
            margin: 50px 20px 0;
            padding: 40px 20px;
            width: 370px;
            box-shadow: 2px 1px 20px 2px rgba(0, 0, 0, 0.105);
            h2 {
                font-size: 20px;
                font-weight: 450;
            }
            .subtotal-row {
                margin: 10px 0 0;
                padding: 20px 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px dotted #e1dfdf;
                color: #8d8d8d;
                .subtotal {
                    font-size: 13px;
                }
            }
            .shipping-row {
                padding: 20px 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px dotted #e1dfdf;
                color: #8d8d8d;
                .shipArr {
                    li {
                        display: flex;
                        padding: 5px 0;
                        font-size: 13px;
                        justify-content: end;
                        .tit {
                            margin-right: 10px;
                        }
                    }
                }
            }
            .total-row {
                padding: 20px 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: #8d8d8d;
                .price {
                    color: #ee7575;
                    font-weight: 600;
                    font-size: 18px;
                }
            }
            .checkout {
                width: 100%;
                background-color: #000;
                color: #fff;
                padding: 10px 0;
                font-size: 13px;
                font-weight: 600;
                display: flex;
                justify-content: center;
                transition: all 0.3s;
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
    .no-product{
        margin: 50px 0;
        height: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        h1{
            font-weight: normal;
            margin: 10px 0 20px;
            font-size: 24px;
        }
        .btn{
            font-size: 12px;
            padding: 5px 10px;
            background-color: #000;
            color: #fff;
        }
    }
}

@media screen and (max-width: 75rem) {
    .cart-content {
        .cart-body {
            width: 100%;
            padding: 0 1.25rem;
        }
    }
}
@media screen and (max-width: 51.25rem) {
    .cart-content {
        .cart-body{
            .product-box {
                display: none;
            }
            .mini-cart {
                display: block;
                width: 100%;
                margin: auto;
            }
            .check-row {
                margin: 1.875rem auto 0;
            }
        }
    }
}
@media screen and (max-width: 33.75rem) {
    .cart-content {
        .cart-body{
            margin: 0 auto 3.125rem;
            padding: 0;
            justify-content: center;
            .mini-cart {
                width: 100%;
                .detail-row {
                    .name {
                        .title {
                            font-size: .75rem;
                            max-width: 7.5rem;
                        }
                        .sku {
                            font-size: .75rem;
                        }
                    }
                }
            }
            .check-row {
                margin: 0 1.25rem;
            }
        }
    }
}
@media screen and (max-width: 375px) {
    .cart-content {
        .cart-body{
            margin: 0 auto 3.125rem;
            padding: 0;
            justify-content: center;
            .mini-cart {
                width: 100%;
                .detail-row {
                    .name {
                        .title {
                            font-size: .75rem;
                            max-width: 110px;
                        }
                        .sku {
                            font-size: .75rem;
                        }
                    }
                }
            }
            .check-row {
                margin: 0 1.25rem;
            }
        }
    }
}
